<template>
  <div class="detailBox">
    <navBar />
    <div class="box">
      <div class="safe2">
        <div class="header">
          <h3>{{ info.name }}</h3>
          <p class="t2">
            <span>{{ userInfo.teacher_name }}</span
            ><span>创建于{{ info.update_at }}</span>
          </p>
          <span class="t3">发布时间：{{ info.update_at }}</span>
          <span class="t4">资料</span>
        </div>
        <div class="radioBox">
          <video
            id="myvideo"
            controls
            @ended="onVideoEnded"
            style="width: 100%; height: 100%; object-fit: contain"
            :src="info.url"
            controlsList="nodownload"
            oncontextmenu="return false"
            ref="myvideo"
            @canplay="getVidDur()"
          ></video>
        </div>
        <div style="display: flex; align-items: center; margin: 20px 0">
          <p class="allCommentTitle">
            全部评论：<span class="allCommentTotal"
              >共{{ commentListData.length }}条</span
            >
          </p>
        </div>
        <el-empty
          description="暂无评论"
          v-if="!commentListData.length"
        ></el-empty>
        <ul class="commentDataUl">
          <li v-for="(item, index) in commentListData" :key="index">
            <p class="content">{{ item.content }}</p>
            <div
              v-if="item.file.length"
              style="margin-top: 10px; display: flex; align-items: center"
            >
              <div v-for="items in item.file" :key="items.id">
                <div class="demo-image__preview">
                  <div v-if="items.file_type == 2">
                    <el-image
                      style="width: 100px; height: 100px; margin-right: 10px"
                      :src="items.path"
                      @click="openImagePreview(items.path)"
                      :preview-src-list="srcList"
                    >
                    </el-image>
                  </div>
                </div>
                <div
                  v-if="items.file_type == 1"
                  style="width: 160px; height: 100px"
                >
                  <video
                    :src="items.path"
                    controls
                    style="width: 100%; height: 100%; object-fit: fill"
                  ></video>
                </div>
                <div v-if="items.file_type != 1 && items.file_type != 2">
                  <a
                    :href="items.path"
                    style="text-decoration: none; margin-right: 10px"
                    class="aaaLink"
                    >{{ items.name }}
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <navFooter />
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar.vue";
import navFooter from "@/components/navFooter/navFooter.vue";
import { lookRecord, commentList, fileList } from "@/api/order.js";
import axios from "axios";

export default {
  data() {
    return {
      activeName: "first",
      form: {
        name: "123",
      },
      info: {},
      userInfo: {},
      endTime: "",
      open_time: "",
      close_time: "",
      timeSta: "",
      timeStaDown: "",
      lunId: "",
      firstOpenTime: 0, // 第一次开始播放时间
      commentListData: [],
      srcList: [],
      total: 0,
      page: 1,
      grade_id: "",
      is_give: "",
      indexNow: "",
      danArr: [],
      allData: [],
      lookedLevel: [],
    };
  },
  watch: {
    $route: {
      handler(to, form) {},
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  beforeRouteLeave(to, from, next) {
    let time = new Date();
    let close_time = this.timestampToTime(
      time.toLocaleString("chinese", { hour12: false }).split(" ")
    );
    this.close_time = close_time;
    this.sendTime();
    next();
  },
  beforeDestroy() {
    let time = new Date();
    let close_time = this.timestampToTime(
      time.toLocaleString("chinese", { hour12: false }).split(" ")
    );
    this.close_time = close_time;
    this.sendTime();
  },
  mounted() {
    let a = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = a;
    this.info = JSON.parse(this.$route.query.item);
    this.grade_id = this.$route.query.grade_id;
    this.is_give = this.$route.query.is_give;
    let levels = this.info.section.split("/");
    this.lookedLevel = this.info.section.split("/");
    console.log(levels);
    console.log({ ...this.info });
    // 单层目录
    // if (levels.length - 1 <= 2 && this.info.suffix == "mp4") {
    let params = {
      dir:
        this.info.is_give == 0
          ? levels[0] + "/"
          : levels[0] + "/" + levels[1] + "/",
      is_give: this.is_give,
      grade_id: this.grade_id,
    };
    fileList({ ...params }).then((res) => {
      this.danArr = res.data.filter((item) => item.suffix == "mp4");
      console.log("单机的");
      console.log(this.danArr);
      this.indexNow = this.danArr.findIndex(
        (item) => item.name == this.info.name
      );
      console.log(this.indexNow);
      if (!this.danArr.length) {
        this.allData = res.data;
        sessionStorage.setItem("allData", JSON.stringify(this.allData));
        console.log("不是单机的");
        console.log(this.allData);
        // 1.获取当前目录所在位置索引
        this.indexNow = this.allData.findIndex(
          (item) => item.name == levels[1]
        );
        console.log(this.indexNow);
        // 2.筛选出当前目录内的mp4
        let params2 = {
          dir: this.allData[this.indexNow].path,
          is_give: this.is_give,
          grade_id: this.grade_id,
        };
        fileList({ ...params2 }).then((res) => {
          console.log("获取目录所有文件", res.data);
          this.danArr = res.data.filter((item) => item.suffix == "mp4");
          console.log(this.danArr);
          this.indexNow = this.danArr.findIndex(
            (item) => item.name == this.info.name
          );
        });
      }
    });
    // }

    this.$refs.myvideo.currentTime = this.info.end_time;
    let _this = this;
    window.onbeforeunload = function (e) {
      if (_this.$route.name == "studyDetail") {
        e = e || window.event;
        let time = new Date();
        let close_time = _this.timestampToTime(
          time.toLocaleString("chinese", { hour12: false }).split(" ")
        );
        _this.close_time = close_time;
        _this.sendTime();
        // 兼容IE8和Firefox 4之前的版本
        // if (e) {
        //   e.returnValue = "关闭提示";
        // }
        // // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        // return "关闭提示";
      } else {
        // window.onbeforeunload = null;
      }
    };
    this.getCommentList();
  },
  methods: {
    judgeClient() {
      let client = "";
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        client = "iOS";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        client = "Android";
      } else {
        client = "PC";
      }
      return client;
    },

    handleCurrentChange(size) {
      this.page = size;
      this.getCommentList();
    },
    getCommentList() {
      commentList({ e_tag: this.info.eTag, page: this.page }).then((res) => {
        this.commentListData = res.data;
        this.total = res.total;
      });
    },
    openImagePreview(path) {
      this.srcList.push(path);
      this.srcList = this.srcList.filter((item) => {
        return item == path;
      });
    },
    getVidDur() {
      var videoTime = document.getElementById("myvideo");
      let all_time = videoTime.duration;
      let end_time = videoTime.currentTime;
      let obj = { all_time, end_time };
      let that = this;

      videoTime.addEventListener("play", function (e) {
        if (e.timeStamp != that.timeSta) {
          console.log("开始播放");
          that.timeSta = e.timeStamp;
          let time = new Date();
          let open_time = that.timestampToTime(
            time.toLocaleString("chinese", { hour12: false }).split(" ")
          );
          if (that.firstOpenTime == 0) {
            that.firstOpenTime = open_time;
          }
          that.open_time = open_time;
          that.lunId = setInterval(() => {
            let time = new Date();
            let close_time = that.timestampToTime(
              time.toLocaleString("chinese", { hour12: false }).split(" ")
            );
            that.close_time = close_time;
            that.sendTime();
          }, 20000);
        }
      });
      // videoTime.addEventListener("timeupdate", function (e) {});
      // videoTime.addEventListener("ended", function (e) {});
      if (this.judgeClient() == "iOS") {
        videoTime.addEventListener("playing", function () {
          clearInterval(that.lunId);
          let time = new Date();
          let open_time = that.timestampToTime(
            time.toLocaleString("chinese", { hour12: false }).split(" ")
          );
          if (that.firstOpenTime == 0) {
            that.firstOpenTime = open_time;
          }
          that.open_time = open_time;
          that.lunId = setInterval(() => {
            let time = new Date();
            let close_time = that.timestampToTime(
              time.toLocaleString("chinese", { hour12: false }).split(" ")
            );
            that.close_time = close_time;
            that.sendTime();
          }, 20000);
        });
      }

      videoTime.addEventListener("pause", function (e) {
        if (e.timeStamp != that.timeStaDown) {
          console.log("暂停播放");
          that.timeStaDown = e.timeStamp;
          let time = new Date();
          let close_time = that.timestampToTime(
            time.toLocaleString("chinese", { hour12: false }).split(" ")
          );
          clearInterval(that.lunId);
          that.close_time = close_time;
          that.sendTime();
        }
      });
      return obj;
    },
    onVideoEnded() {
      console.log(this.danArr);
      console.log(this.indexNow);
      if (this.danArr.length > this.indexNow + 1) {
        this.indexNow++;
        let routeData = this.$router.resolve({
          path: "/studyDetail",
          query: {
            item: JSON.stringify(this.danArr[this.indexNow]),
            grade_id: this.grade_id,
            is_give: this.is_give,
          },
        });
        window.open(routeData.href, "_self");
        this.$message.warning("即将跳转下一课程");
        setTimeout(() => {
          location.reload();
        }, 2000);
      } else {
        let arrs = JSON.parse(sessionStorage.getItem("allData"));
        let index = arrs.findIndex((item) => {
          return item.name == this.lookedLevel[1];
        });
        console.log("小目录放完啦");
        console.log(arrs);
        console.log(this.lookedLevel);
        console.log(index);
        if (index < arrs.length) {
          if (index + 1 >= arrs.length) {
            this.$message.warning("本小章节视频已播放完毕");
            return;
          }
          let params = {
            dir: arrs[index + 1].path,
            is_give: this.is_give,
            grade_id: this.grade_id,
          };
          fileList({ ...params }).then((res) => {
            console.log("获取下一个课程信息");
            console.log(res.data);
            let danArr = res.data.filter((item) => item.suffix == "mp4");
            if (danArr.length) {
              let routeData = this.$router.resolve({
                path: "/studyDetail",
                query: {
                  item: JSON.stringify(danArr[0]),
                  grade_id: this.grade_id,
                  is_give: this.is_give,
                },
              });
              window.open(routeData.href, "_self");
              this.$message.warning("即将跳转下一课程");
              setTimeout(() => {
                location.reload();
              }, 2000);
            } else {
              this.$message.warning("下一小整洁");
            }
          });
        } else {
          this.$message.warning("本小章节视频已播放完毕");
          return;
        }
      }
    },
    timestampToTime(times) {
      let time = times[1];
      let mdy = times[0];
      mdy = mdy.split("/");
      let year = parseInt(mdy[0]);
      let month = parseInt(mdy[1]);
      let day = parseInt(mdy[2]);
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      return year + "-" + month + "-" + day + " " + time;
    },
    sendTime() {
      let params = {
        e_tag: this.info.eTag,
        section: this.info.section,
        total_hours: this.getVidDur().all_time,
        open_time: this.open_time,
        end_time: this.getVidDur().end_time,
        firstOpenTime: this.firstOpenTime,
        video_name: this.info.name,
        close_time: this.close_time ? this.close_time : "close_time",
        is_give: this.is_give,
      };
      lookRecord({ ...params }).then((res) => {});
    },
    showInputFn() {
      this.showInput = true;
    },
  },
  components: { navBar, navFooter },
};
</script>
<style lang="scss" scoped>
html,
body {
  height: 100%;
}
.detailBox {
  min-height: 100vh;
  position: relative;
}
.safe2 {
  width: 83vw;
  margin: auto;
  padding: 30px 20px;
  box-sizing: border-box;
  .header {
    border: 1px solid #dadce0;
    padding: 40px 24px 24px;
    box-sizing: border-box;
    position: relative;
    border-radius: 4px;
    h3 {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .t2 {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          color: #3c4043;
          font-size: 18px;
          font-weight: bold;
        }
        &:last-child {
          margin: 0 14px;
          color: #74787c;
        }
      }
    }
    .t3 {
      display: inline-block;
      background-color: #ecf3fe;
      border-color: #d9e7fd;
      font-size: 12px;
      color: #4285f4;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      padding: 4px;
      box-sizing: border-box;
      margin-top: 10px;
    }
    .t4 {
      display: inline-block;
      padding: 6px 16px;
      box-sizing: border-box;
      position: absolute;
      border-radius: 4px;
      left: 0;
      top: 0;
      background: #71d516;
      color: #fff;
    }
  }
  .radioBox {
    width: 100%;
    height: 620px;
    margin-top: 40px;
    margin-bottom: 60px;
  }
}
.fileUl {
  margin-top: 80px;
  .allCommentTitle {
    font-size: 20px;
  }
  .allCommentTotal {
    font-size: 14px;
  }
  li {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background: #f5f7fa;
    }
  }
}
.commentDataUl {
  li {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    margin-bottom: 10px;
    .content {
      font-size: 14px;
    }
    .el-icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      display: none;
    }
    &:hover {
      .el-icon-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        display: block;
      }
    }
  }
}
::v-deep .el-pagination {
  margin-bottom: 100px;
  text-align: center;
}
</style>
